import React from "react";
import Input from "../../../../ui/common/Input";
import ToggleSwitch from "../../../../ui/toggleSwitch/ToggleSwitch";
import "./TentaclesSettingsForms.scss";

const ABTestSettingsForm = ({ data, handleChanges }) => {
  return (
    <div className="inputs-container">
      <Input
        label="AB Confidence Alpha"
        type="number"
        value={data.ABConfidenceAlpha.value || data.ABConfidenceAlpha.default}
        onChange={(e) => {
          handleChanges("ABConfidenceAlpha", e.target.valueAsNumber);
        }}
        fullWidth
        separateLines
      />
      <Input
        label="AB Uniform Distribution Time"
        type="number"
        value={data.ABUniformDistributionTime.value || data.ABUniformDistributionTime.default}
        onChange={(e) => {
          handleChanges("ABUniformDistributionTime", e.target.valueAsNumber);
        }}
        fullWidth
        separateLines
      />
      <Input
        label="AB aggression Factor"
        type="number"
        value={data.ABaggressionFactor.value || data.ABaggressionFactor.default}
        onChange={(e) => {
          handleChanges("ABaggressionFactor", e.target.valueAsNumber);
        }}
        fullWidth
        separateLines
      />
      <Input
        label="AB archive Days"
        type="number"
        value={data.ABarchiveDays.value || data.ABarchiveDays.default}
        onChange={(e) => {
          handleChanges("ABarchiveDays", e.target.valueAsNumber);
        }}
        fullWidth
        separateLines
      />
      <div className="switch-container">
        <ToggleSwitch
          checked={
            data.ABimageTesting.value !== undefined && data.ABimageTesting.value !== null
              ? data.ABimageTesting.value
              : data.ABimageTesting.default
          }
          onChange={(e) => {
            handleChanges("ABimageTesting", e);
          }}
        />
        <span className="switch-label"> AB image Testing</span>
      </div>
      <Input
        label="AB margin Factor"
        type="number"
        value={data.ABmarginFactor.value || data.ABmarginFactor.default}
        onChange={(e) => {
          handleChanges("ABmarginFactor", e.target.valueAsNumber);
        }}
        fullWidth
        separateLines
      />
      <Input
        label="AB margin Start"
        type="number"
        value={data.ABmarginStart.value || data.ABmarginStart.default}
        onChange={(e) => {
          handleChanges("ABmarginStart", e.target.valueAsNumber);
        }}
        fullWidth
        separateLines
      />
      <Input
        label="AB max Clicks"
        type="number"
        value={data.ABmaxClicks.value || data.ABmaxClicks.default}
        onChange={(e) => {
          handleChanges("ABmaxClicks", e.target.valueAsNumber);
        }}
        fullWidth
        separateLines
      />
      <Input
        label="AB max Runtime"
        type="number"
        value={data.ABmaxRuntime.value || data.ABmaxRuntime.default}
        onChange={(e) => {
          handleChanges("ABmaxRuntime", e.target.valueAsNumber);
        }}
        fullWidth
        separateLines
      />
      <Input
        label="AB probability Factor"
        type="number"
        value={data.ABprobabilityFactor.value || data.ABprobabilityFactor.default}
        onChange={(e) => {
          handleChanges("ABprobabilityFactor", e.target.valueAsNumber);
        }}
        fullWidth
        separateLines
      />
      <Input
        label="AB probability Start"
        type="number"
        value={data.ABprobabilityStart.value || data.ABprobabilityStart.default}
        onChange={(e) => {
          handleChanges("ABprobabilityStart", e.target.valueAsNumber);
        }}
        fullWidth
        separateLines
      />
      <div className="switch-container">
        <ToggleSwitch
          checked={
            data.ABuseCTR.value !== undefined && data.ABuseCTR.value !== null
              ? data.ABuseCTR.value
              : data.ABuseCTR.default
          }
          onChange={(e) => {
            handleChanges("ABuseCTR", e);
          }}
        />
        <span className="switch-label"> AB use CTR</span>
      </div>
      <div className="switch-container">
        <ToggleSwitch
          checked={
            data.ABuseLTR.value !== undefined && data.ABuseLTR.value !== null
              ? data.ABuseLTR.value
              : data.ABuseLTR.default
          }
          onChange={(e) => {
            handleChanges("ABuseLTR", e);
          }}
        />
        <span className="switch-label"> AB use LTR</span>
      </div>
      <div className="switch-container">
        <ToggleSwitch
          checked={
            data.ABuseCTRConfidence.value !== undefined && data.ABuseCTRConfidence.value !== null
              ? data.ABuseCTRConfidence.value
              : data.ABuseCTRConfidence.default
          }
          onChange={(e) => {
            handleChanges("ABuseCTRConfidence", e);
          }}
        />
        <span className="switch-label"> AB use CTR Confidence</span>
      </div>
      <div className="switch-container">
        <ToggleSwitch
          checked={
            data.ABuseLTRConfidence.value !== undefined && data.ABuseLTRConfidence.value !== null
              ? data.ABuseLTRConfidence.value
              : data.ABuseLTRConfidence.default
          }
          onChange={(e) => {
            handleChanges("ABuseLTRConfidence", e);
          }}
        />
        <span className="switch-label"> AB use LTR Confidence</span>
      </div>
      <div className="switch-container">
        <ToggleSwitch
          checked={
            data.ABuseSmartOctoScore.value !== undefined && data.ABuseSmartOctoScore.value !== null
              ? data.ABuseSmartOctoScore.value
              : data.ABuseSmartOctoScore.default
          }
          onChange={(e) => {
            handleChanges("ABuseSmartOctoScore", e);
          }}
        />
        <span className="switch-label"> AB use SmartOcto Score </span>
      </div>
      <div className="switch-container">
        <ToggleSwitch
          checked={
            data.ABuseThompson.value !== undefined && data.ABuseThompson.value !== null
              ? data.ABuseThompson.value
              : data.ABuseThompson.default
          }
          onChange={(e) => {
            handleChanges("ABuseThompson", e);
          }}
        />
        <span className="switch-label"> AB use Thompson </span>
      </div>
      <Input
        label="Minimal CTR"
        type="number"
        value={data.minimalCTR.value || data.minimalCTR.default}
        onChange={(e) => {
          handleChanges("minimalCTR", e.target.valueAsNumber);
        }}
        fullWidth
        separateLines
      />
      <Input
        label="Minimal CTR diff"
        type="number"
        value={data.minimalCTRdiff.value || data.minimalCTRdiff.default}
        onChange={(e) => {
          handleChanges("minimalCTRdiff", e.target.valueAsNumber);
        }}
        fullWidth
        separateLines
      />
            <Input
        label="Minimal LTR"
        type="number"
        value={data.minimalLTR.value || data.minimalLTR.default}
        onChange={(e) => {
          handleChanges("minimalLTR", e.target.valueAsNumber);
        }}
        fullWidth
        separateLines
      />
      <Input
        label="Minimal LTR diff"
        type="number"
        value={data.minimalLTRdiff.value || data.minimalLTRdiff.default}
        onChange={(e) => {
          handleChanges("minimalLTRdiff", e.target.valueAsNumber);
        }}
        fullWidth
        separateLines
      />
      <Input
        label="Minimal Total Impressions"
        type="number"
        value={data.minimalTotalImpressions.value || data.minimalTotalImpressions.default}
        onChange={(e) => {
          handleChanges("minimalTotalImpressions", e.target.valueAsNumber);
        }}
        fullWidth
        separateLines
      />
      <div className="switch-container">
        <ToggleSwitch
          checked={
            data.storeHeadertestStatistics.value !== undefined && data.storeHeadertestStatistics.value !== null
              ? data.storeHeadertestStatistics.value
              : data.storeHeadertestStatistics.default
          }
          onChange={(e) => {
            handleChanges("storeHeadertestStatistics", e);
          }}
        />
        <span className="switch-label"> Store Headertest Statistics </span>
      </div>
    </div>
  );
};

export default ABTestSettingsForm;
