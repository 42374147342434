import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import BundledStoriesTable from "./BundledStoriesTable";
import "./Bundle.scss";
import StackedAreaGraphContainer from "../graphs/StackedAreaGraph/StackedAreaGraphContainer";
import { CircleLoader } from "../../icons/Loaders";
import { CloseIcon } from "../../icons/Icons";
import { deleteBundle } from "../../store/slices/bundles";
import { useAppDispatch } from "../../store";
import { useNavigate } from "react-router";
import { DateTime } from "luxon";

const Bundle = () => {
  const isLoading = useSelector((state: any) => state.bundles.isBundleLoading);
  const bundleData = useSelector((state: any) => state.bundles.bundleData);

  const currentBrand = useSelector((state: any) => state.currentBrand.data);

  const isLoadingBundleGraphData = useSelector((state: any) => state.bundles.isBundleGraphDataLoading);
  const bundleGraphDataStory = useSelector((state: any) => state.bundles.bundleGraphData?.graphdata);

  const isLoadingBundleGraphDataChannel = useSelector((state: any) => state.bundles.isBundleGraphDataChannelLoading);
  const bundleGraphDataChannel = useSelector((state: any) => state.bundles.bundleGraphDataChannel?.graphdata);

  const [viewBy, setViewBy] = useState("story");

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleDeleteBundle = (bundleId) => {
    if (bundleId) {
      const confirmation = window.confirm("Are you sure?");

      if (confirmation) {
        dispatch(deleteBundle(bundleId)).then(() => {
          navigate(`/bundles`);
        });
      }
    }
  };

  const bundleGraphDataStoryFormattedColors = useMemo(() => {
    if (!bundleGraphDataStory) return [];

    const colorScheme = ["#0B8F83", "#50E3C2", "#04BFB1"];
    let colorCounter = 0;
    let graphData = [] as Array<any>;
    bundleGraphDataStory.forEach((element) => {
      graphData.push({ ...element, color: colorScheme[colorCounter] });
      colorCounter++;
      if (colorCounter === 3) colorCounter = 0;
    });
    return [...graphData].reverse();
  }, [bundleGraphDataStory]);

  const graphDataToDisplay = useMemo(() => {
    if (viewBy === "story") {
      return bundleGraphDataStoryFormattedColors;
    } else {
      return bundleGraphDataChannel;
    }
  }, [viewBy, bundleGraphDataStory, bundleGraphDataChannel]);

  const iconsData = useMemo(() => {
    return bundleData?.bundle_stories?.map((story) => {
      return story.post;
    });
  }, [bundleData]);

  const shouldShowLegend = useMemo(() => {
    return viewBy === "origin";
  }, [viewBy]);

  const lastBundleStory = useMemo(() => {
    if (!bundleData.bundle_stories) return "";
    if (!bundleData?.bundle_stories[0]) return "";
    let date = new Date(
      DateTime.fromMillis(bundleData?.bundle_stories[0]?.post.pubdate)
        .setZone(currentBrand.domainSettings.timezone)
        .toFormat("yyyy-MM-dd HH:mm"),
    ).toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    });
    return `'${bundleData?.bundle_stories[0]?.post.title}' at ${date}`;
  }, [bundleData, currentBrand]);

  return (
    <div className="bundle-container">
      <header>
        {isLoading ? (
          <>
            <CircleLoader />
          </>
        ) : (
          <>
            <p className="bundle-count">A bundle of {bundleData?.bundle_story_count} stories</p>
            <h1>{bundleData?.bundle_name}</h1>
            <button
              type="button"
              onClick={(e) => {
                handleDeleteBundle(bundleData?.bundle_id);
              }}
            >
              delete
              <span className="bundle-del-icon">
                <CloseIcon color="#000" />
              </span>
            </button>
          </>
        )}
      </header>
      <div className="catch">
        <h2>Catch</h2>
        <div className="pageviews-graph-container">
          <div className="bundle-viewby-selector">
            <span>view by:</span>
            <span
              className={`switch-button story ${viewBy === "story" ? "active" : "not-active"}`}
              onClick={() => {
                setViewBy("story");
              }}
            >
              story
            </span>
            <span
              className={`switch-button origin ${viewBy === "origin" ? "active" : "not-active"}`}
              onClick={() => {
                setViewBy("origin");
              }}
            >
              origin
            </span>
          </div>
          <h3>Pageviews</h3>
          {isLoading || isLoadingBundleGraphData || isLoadingBundleGraphDataChannel ? (
            <div className="loader-container">
              <CircleLoader />
            </div>
          ) : (
            <>
              <StackedAreaGraphContainer
                data={graphDataToDisplay || []}
                showLagend={shouldShowLegend}
                secondaryPosts={iconsData}
                graphInitialWidth={1010}
              />
              {viewBy === "story" && <p className="last-story-info">Last story added: {lastBundleStory}</p>}
            </>
          )}
        </div>
      </div>
      <div className="bundled-stories">
        <div className="bundled-stories-table-title">
          <h2>Bundled Stories</h2>
          <div className="bundles-table-labels-container">
            <div>
              Pageviews <br /> 1000x
            </div>
            <div>
              Engagement <br /> 100x
            </div>
          </div>
        </div>
        {isLoading ? <CircleLoader /> : <BundledStoriesTable stories={bundleData.bundle_stories} />}
      </div>
    </div>
  );
};

export default Bundle;
