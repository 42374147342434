import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Buffer } from "buffer";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../store";
import { useSelector } from "react-redux";
import { asyncLogin, clearErrorState } from "../store/slices/auth";
import { LogoIcon } from "../icons/Icons";
import { loginViaBrandSwith } from "../store/slices/auth";
import { logoutWhenWrongIDPEmail } from "../store/slices/auth";
import ApiCore from "../store/api/ApiCore";

import "./Login.scss";
import { CircleLoader } from "../icons/Loaders";
import LoadingPage from "./LoadingPage";

const Login = () => {
  const dispatch = useAppDispatch();
  const API = useMemo(() => {
    return new ApiCore();
  }, []);
  const authError = useSelector((state: any) => state.auth.error);
  const authLoading = useSelector((state: any) => state.auth.loading);
  const user = useSelector((state: any) => state.auth.token);
  const brandId = API.getBrandId();
  const [userid, setUserid] = useState("");
  const [emailCheckLoading, setEmailCheckLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState<null | string>();
  const emailRef = useRef<HTMLInputElement>(null);
  const passRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  var urlParams = new URLSearchParams(window.location.search);

  const [loginStep, setLoginStep] = useState(1);

  const cognito_client_id = process.env.REACT_APP_COGNITO_CLIENT_ID;
  const cognito_domain = process.env.REACT_APP_COGNITO_DOMAIN;

  const emailConfirmButtonEnabled = useMemo(() => {
    return !(userid.trim() !== "");
  }, [userid]);

  const passwordConfirmButtonEnabled = useMemo(() => {
    return !(password.trim() !== "");
  }, [password]);

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user]);
  useEffect(() => {
    document.title = "Login - Smartocto real-time";
  }, []);

  useEffect(() => {
    dispatch(clearErrorState());
  }, [dispatch]);

  const setAwsState = (brand_Id) => {
    return btoa(
      JSON.stringify({
        brand_id: brand_Id,
        app: "so",
      }),
    );
  };

  const openCognito = useCallback(() => {
    const subdomain = window.location.hostname.split(".")[0].split("-")[0];
    const redirectDomain = window.location.origin.replace(subdomain, "id-auth");
    let redirectUrl = redirectDomain + "/login";
    const url = `https://${cognito_domain}/login?response_type=code&client_id=${cognito_client_id}&redirect_uri=${redirectUrl}&state=${setAwsState(
      brandId,
    )}`;
    window.open(url, "_self");
  }, [brandId, cognito_client_id, cognito_domain]);

  // handle cognito auth
  var urlParams = new URLSearchParams(window.location.search);

  const getStateParam = (aws_state, name) => {
    try {
      var objState = JSON.parse(Buffer.from(aws_state, "base64").toString("utf-8"));
      return objState[name];
    } catch (e) {
      throw new Error("state argument not exist");
    }
  };

  const getCognitoToken = async (code, state_app, brand_id) => {
    try {
      const result = await API.get(`/api/auth/token/${code}/?app=${state_app}&brand_id=${brand_id}`);
      return result;
    } catch {
      dispatch(logoutWhenWrongIDPEmail(brand_id));
    }
  };

  useEffect(() => {
    const fetchTokenAndRedirect = async () => {
      if (urlParams.has("code")) {
        const code = urlParams.get("code");
        const state = urlParams.get("state");
        const state_app = getStateParam(state, "app");
        const brand = getStateParam(state, "brand_id");

        const payLoad = await getCognitoToken(code, state_app, brand);

        if (payLoad && payLoad["login_status"]) {
          let url;

          if (state_app === "tentacles") {
            const rawUrl = new URL(getStateParam(state, "url"));
            document.cookie =
              "tentacles_cognito_redirect=" +
              rawUrl.href +
              "; path=/; domain=" +
              window.location.hostname +
              "; max-age=2592000; SameSite=None; Secure";

            rawUrl.searchParams.append("token", payLoad["token_refresh"]);
            url = rawUrl.href;
            window.open(url, "_self");
          } else if (state_app === "ci" && payLoad.hasOwnProperty("url")) {
            window.open(payLoad["url"], "_self");
          } else {
            url = window.location.origin.replace("id-auth", brand);
            dispatch(loginViaBrandSwith());
            window.open(url, "_self");
          }
        } else {
          if (state_app === "ci" && payLoad.hasOwnProperty("error_url")) {
            window.open(payLoad["error_url"], "_self");
          }
        }
      }
    };

    fetchTokenAndRedirect();
  }, []);

  // auto-focusing elements
  useEffect(() => {
    if (loginStep === 1 && emailRef.current) {
      emailRef.current.focus();
    } else if (loginStep === 2 && passRef.current) {
      passRef.current.focus();
    }
  }, [loginStep, emailRef, passRef]);

  const handleSubmit = useCallback(async () => {
    await dispatch(asyncLogin({ userid, password }));
  }, [dispatch, password, userid]);

  useEffect(() => {
    if (authError) {
      setEmailError("Invalid password or email address");
    } else {
      setEmailError("");
    }
    if (urlParams.has("error")) {
      setEmailError("Invalid password or email address");
    }
  }, [authError]);

  const handleConfirmEmail = useCallback(async () => {
    try {
      setEmailCheckLoading(true);
      await API.get(`/api/user/idp/${brandId}/${userid}`).then((response) => {
        setEmailCheckLoading(false);
        dispatch(clearErrorState());
        if (response.idp === "smartocto") {
          setLoginStep(2);
        } else {
          openCognito();
        }
      });
    } catch (err) {
      setEmailCheckLoading(false);
      setEmailError("Enter a valid email address");
    }
  }, [userid, brandId, setLoginStep, API, openCognito]);

  const handleEnterPress = useCallback(
    (e: any) => {
      if (e.key === "Enter") {
        if (loginStep === 1) {
          handleConfirmEmail();
        }
        if (loginStep === 2) {
          handleSubmit();
        }
      }
    },
    [loginStep, handleConfirmEmail, handleSubmit],
  );

  useEffect(() => {
    document.addEventListener("keydown", handleEnterPress);
    return () => {
      document.removeEventListener("keydown", handleEnterPress);
    };
  }, [handleEnterPress]);

  if (urlParams.has("code")) {
    return <LoadingPage />;
  }

  return (
    <div className="login-page">
      <div className="login-form">
        <div className="login-logo-container">
          <LogoIcon />
        </div>
        <input
          type="email"
          placeholder="email address"
          value={userid}
          disabled={loginStep === 2}
          onChange={(e) => {
            setEmailError(null);
            setUserid(e.target.value);
          }}
          ref={emailRef}
        />
        {loginStep === 2 && (
          <input
            type="password"
            placeholder="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            ref={passRef}
          />
        )}
        {loginStep === 1 ? (
          <button
            type="button"
            disabled={emailConfirmButtonEnabled}
            onClick={() => {
              handleConfirmEmail();
            }}
          >
            next
          </button>
        ) : (
          <>
            <button
              type="button"
              disabled={passwordConfirmButtonEnabled}
              onClick={() => {
                handleSubmit();
              }}
            >
              login
            </button>
            <div className="login-action-buttons">
              <Link to="/forgot"> Forgot password? </Link>
              <Link to="/"> Edit Email </Link>
            </div>
          </>
        )}
        <>
          {(authLoading || emailCheckLoading) && (
            <div className="login-loader-container">
              <CircleLoader color="#fff" width="40px" height="40px" />
            </div>
          )}
        </>
        <> {emailError ? <div className="email-error">{emailError}</div> : ""}</>
      </div>
    </div>
  );
};

export default Login;
